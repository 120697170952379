import { useEffect, useState } from 'react';

import './Input.scss';

export default function Input(props) {

   const [value, setValue] = useState('');
   const [focused, setFocused] = useState(false);
   const [showPassword, setShowPassword] = useState(false);

   const [passwordRequirements, setPasswordRequirements] = useState({
      minimumLength: false,
      lowercaseLetter: false,
      uppercaseLetter: false,
      number: false
   });
   
   const checkPassword = (e) => {

      const requirements = {
         minimumLength: e.target.value.length >= 10,
         lowercaseLetter: /[a-z]/.test(e.target.value),
         uppercaseLetter: /[A-Z]/.test(e.target.value),
         number: /[0-9]/.test(e.target.value),
      }
      setPasswordRequirements(requirements);

   }

   function validateIBAN(iban) {
      iban = iban.replace(/ /g, '').toUpperCase();
    
      if (!/^[A-Z0-9]{2,34}$/.test(iban)) return false;
    
      const rearranged = iban.slice(4) + iban.slice(0, 4);
      const numericIBAN = rearranged.split('').map(char => {
        const code = char.charCodeAt(0);
        return (code >= 'A'.charCodeAt(0) && code <= 'Z'.charCodeAt(0)) ? code - 'A'.charCodeAt(0) + 10 : char;
      }).join('');
    
      return modulo97(numericIBAN) === 1;
    }
    
    function modulo97(iban) {
      let remainder = iban;
      let block;
    
      while (remainder.length > 2) {
        block = remainder.slice(0, 9);
        remainder = parseInt(block, 10) % 97 + remainder.slice(block.length);
      }
    
      return parseInt(remainder, 10) % 97;
    }

   const validateValue = (e, newValue) => {

      if (!props.required && newValue.length === 0) {
         return null;
      }

      let error;
      if (newValue.length < props.minLength) {
         error = `Gebruik minimaal ${props.minLength} tekens.`;
      } else if (e.target.getAttribute('name') == 'email' && !/.+@.+\..{2,}/.test(newValue)) {
         error = 'Vul een correct e-mailadres in.';
      } else if (props.noContactInfo) {
         if (/[0-9\s-]{6,}/.test(newValue)) {
            error = 'Het lijkt erop dat je een telefoonnummer vermeldt. Dit is niet toegestaan.'; 
         } else if (newValue.includes('@')) {
            error = 'Het lijkt erop dat je een e-mailadres vermeldt. Dit is niet toegestaan.'; 
         }
      } else if (props.iban) {
         if (!validateIBAN(newValue)) {
            error = 'Dit is geen correcte IBAN.';
         }
      } else if (props.newPassword) {
         for (const requirementKey of Object.keys(passwordRequirements)) {
            if (!passwordRequirements[requirementKey]) {
               error = 'Het wachtwoord voldoet niet aan de vereisten.';
            }
         }
      } else if (props.numericOnly && parseInt(newValue) > props.max) {
         error = `Deze waarde mag maximaal ${props.max} zijn.`;
      }

      return error;

   }

   const handleInput = (e) => {

      e.target.classList.remove('error');
      e.target.parentElement.classList.remove('valid');
      if (e.target.parentElement.nextElementSibling && e.target.parentElement.nextElementSibling.classList.contains('error-message')) {
         e.target.parentElement.nextElementSibling.remove();
      }
      
      let newValue = e.target.value;
      if (props.maxLength && newValue.length > props.maxLength) {
         newValue = newValue.substring(0, props.maxLength);
      }
      
      if (props.numericOnly) {
         setValue(newValue.replace(/[^0-9]/, ''));
      } else if (props.alphaOnly) {
         setValue(newValue.replace(/[^A-z\s\-']/, ''));
      } else if (props.urlOnly) {
         setValue(newValue.toLowerCase().replace(/[\s-]+/g, '-').replace(/[^0-9a-z\-]/g, ''));
      } else if (props.decimalOnly) {
         newValue = newValue
            .replace(/[,.]+/g, '.')
            .replace(/[^0-9.]/g, '')
            .replace(/(\.[0-9]{2})[0-9]/g, '$1');
         newValue = props.type == "number" ? Math.min(parseFloat(newValue), props.max) : newValue;
         setValue(newValue);
      } else if (props.time) {
         setValue(newValue.replace(/[^0-9:]/, ''));
      } else {
         setValue(newValue);
      }   

      if (props.explicitValidationOnInput && !validateValue(e, newValue)) {
         e.target.parentElement.classList.add('valid');
      }

      if (props.onInput) { props.onInput(e, newValue); }
      if (props.newPassword) { checkPassword(e); }

   }

   const onBlurCorrect = (e) => {

      // on blur correction
      let newValue = String(value).trim().replace(/\s{2,}/, ' ');
      if (props.name == 'zip') {
         if (/^\s*[0-9]{4,}\s*[A-z]{2}\s*$/.test(newValue)) {
            newValue = newValue.replace(/([0-9])([A-z])/g, function(match, p1, p2) {
               return p1 + ' ' + p2;
           }).toUpperCase();
         }
      } else if (props.name == 'housenumber') {
         newValue = newValue.replace(/^[^0-9]+/g, '').toUpperCase().replace(/\s/g, '');
      } else if (['full-name', 'street', 'city', 'name'].includes(props.name) || props.onBlurCapitalize) {
         newValue = newValue.substring(0, 1).toUpperCase() + newValue.substring(1);
      } else if (e.target.getAttribute('name') == 'url') {
         newValue = newValue.toLowerCase();
         newValue = newValue.replace(/[^a-z\s\-]/, '');
         newValue = newValue.replace(/\s{2,}/, ' ');
         newValue = newValue.replace(/[\s-]+/, '-');
      } else if (e.target.getAttribute('name') == 'tel') {
         newValue = newValue.replaceAll(/[^0-9+\-\s]/g, '');
      } else if (props.iban) {
         newValue = newValue.replace(/\s+/g, "").toUpperCase();
         let spaceDelimitedValue = "";
         for (let i = 0; i < newValue.length; i += 4) {
            spaceDelimitedValue += newValue.substring(i, i + 4) + " ";
         }
         if (spaceDelimitedValue) {
            spaceDelimitedValue = spaceDelimitedValue.substring(0, spaceDelimitedValue.length - 1);
         }
         newValue = spaceDelimitedValue;
      } else if (props.time) {

         let intValue = parseInt(newValue);
         if (/^\d+$/.test(newValue) && !isNaN(intValue) && intValue > 0 && intValue < 24) {
            newValue = (intValue < 10 ? '0' + newValue + ':00' : newValue + ':00')
         } else if (/^[0-9]:[0-9]{2}$/.test(newValue)) {
            newValue = '0' + newValue;
         } else if (/[0-2][0-9]:[6-9][0-9]/.test(newValue)) {
            newValue = newValue.substring(0, 3) + '59';
         } else if (!/[0-2][0-9]:[0-5][0-9]/.test(newValue)) {
            newValue = /^([01][0-9]|[2][0-3])/.test(newValue) ? newValue.substring(0, 2) + ':00' : '';
         }

         let minutes;
         if (newValue) {
            const match = newValue.match(/([0-9]{2}):([0-9]{2})/);
            if (match) {
                minutes = match[2];
                if (minutes !== null && parseInt(minutes)) {
                   minutes = String(Math.min(Math.round(minutes / 15), 3) * 15);
                   minutes = minutes == '0' ? '00' : minutes;
                   newValue = match[1] + ':' + minutes;
                }
            }
         }

      }

      // on blur validation
      if (!e.target.classList.contains('error')) {
         
         const error = validateValue(e, newValue);

         if (error) {
            e.target.classList.add('error');
            const errorMessage = document.createElement('p');
            errorMessage.classList.add('error-message');
            errorMessage.textContent = error;
            e.target.parentElement.insertAdjacentElement("afterend", errorMessage);
         } else if (props.explicitValidation) {
            e.target.parentElement.classList.add('valid');
         }

      }

      if (props.onBlur) { 
         props.onBlur(e, newValue, setValue); 
      }

      setValue(newValue);
      setFocused(false);

   }

   const handleKeyDown = (e) => {

      if (props.noTab && e.key == 'Tab') {
         e.preventDefault();
         e.target.blur();
      }

      if (props.onKeyDown) { props.onKeyDown(e); }

   }

   useEffect(() => {
      if (props.defaultValue) {
         
         let defaultValue = props.defaultValue;
         setValue(defaultValue);

      }
   }, [props.defaultValue]);

   return (
      <>

         <div className={"input-container" + (props.icon ? " with-icon" : "") + (props.large ? " large" : "")}>

            {props.icon &&
               <span className="icon">
                  {props.icon}
               </span>
            }

            {!props.multiline &&
               <input 
                  id={props.id}
                  className={(value ? "not-empty" : "") + (props.readOnly ? " readonly" : "")}
                  type={props.type == "password" && showPassword || props.honeypot ? "text" : props.type}
                  name={props.honeypot ? "id_4128592" : props.name}
                  value={value}
                  onInput={handleInput}
                  onBlur={onBlurCorrect}
                  onKeyDown={handleKeyDown}
                  onChange={props.onChange}
                  onFocus={() => { setFocused(true); }}
                  data-lpignore="true"
                  minLength={props.minLength}
                  maxLength={props.maxLength}
                  required={props.required}
                  placeholder={props.example}
                  min={props.min}
                  max={props.max}
                  step={props.step}
                  ref={props.innerRef}
                  readOnly={props.readOnly}
                  inputMode={props.inputMode}
                  autoComplete={props.honeypot ? "off" : props.autoComplete}
                  style={props.honeypot ? {
                     position: "absolute",
                     left: "-9999px"
                  } : {}}
                  />}

            {props.multiline &&
               <textarea
                  id={props.id}
                  className={(value.length > 0 ? "not-empty" : "") + (props.readOnly ? " readonly" : "")}
                  name={props.name}
                  onInput={handleInput}
                  onBlur={onBlurCorrect}
                  onKeyDown={handleKeyDown}
                  onChange={props.onChange}
                  onFocus={() => { setFocused(true); }}
                  data-lpignore="true"
                  rows={props.rows ? props.rows : (props.multilineSmall ? "2": "8")}
                  minLength={props.minLength}
                  maxLength={props.maxLength}
                  required={props.required}
                  autoComplete={props.autoComplete}
                  placeholder={props.example}
                  readOnly={props.readOnly}
                  defaultValue={value}
                  ref={props.innerRef}
                  style={props.honeypot ? {
                     position: "absolute",
                     left: "-9999px",
                  } : {}}
                  ></textarea>}

            <span className="placeholder">
               {props.placeholder}
               {(props.required && !props.hideRequired) && <span className="required">*</span>}
            </span>       

            {(props.maxLength && !props.hideMaxLength) &&
               <span 
                  className={"max-length" + (focused ? " focused" : "") + (value.length / parseInt(props.maxLength) > 0.9 ? " almost-full" : "")}>
                  {String(value).length}/{props.maxLength}
               </span>} 

            <svg className="valid-check" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>

            {props.type == "password" &&
               <div className="show-password"
                  onClick={() => {setShowPassword(!showPassword)}}>
                  {showPassword && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>}
                  {!showPassword && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/></svg>}
               </div>}

         </div>
         
         {(props.newPassword) &&
            <div className="password-requirements">
               <span className={passwordRequirements.minimumLength ? "satisfied" : ""}>10 tekens</span>
               <span className={passwordRequirements.number ? "satisfied" : ""}>1 cijfer</span>
               <span className={passwordRequirements.lowercaseLetter ? "satisfied" : ""}>1 kleine letter</span>
               <span className={passwordRequirements.uppercaseLetter ? "satisfied" : ""}>1 hoofdletter</span>
            </div>
         }

      </>
   );

}
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';
import Profile from '../components/Profile';
import useFetch from '../hooks/useFetch';
import useTokenStorage from '../hooks/useTokenStorage';

import './HubPage.scss';

export default function HubPage() {

   const [ads, setAds] = useState([]);
   
   const [bookings, setBookings] = useState(null);
   
   const location = useLocation();
   const navigate = useNavigate();

   const { user } = useContext(AuthContext);
   const [optionMap, setOptionMap] = useState({});

    // request activities
    const request = {
      activities: {
          endpoint: "/api/v1/activities/personal",
          method: "GET",
      },
      options: {
         endpoint: "/api/v1/activities/options",
         method: "GET",
      },
      favoriteActivities: {
         endpoint: "/api/v1/favorites/activities",
         method: "GET",
      },
      bookings: {
         endpoint: "/api/v1/bookings",
         method: "GET",
      },
   }
   const [data, loading, error] = useFetch(request);
   const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken] = useTokenStorage();

   const [favoriteAds, setFavoriteAds] = useState([]);
   useEffect(() => {
       if (data.favoriteActivities && data.favoriteActivities.data) {
           setFavoriteAds(data.favoriteActivities.data);
       }

      if (data.options && data.options.data) {
         const newOptionMap = {};
         for (const option of data.options.data) {
            newOptionMap[option.id] = option;
         }
         setOptionMap(newOptionMap);
      }

   }, [loading]);

   useEffect(() => {
       const shownAds = document.getElementsByClassName('ad');
       for (const ad of shownAds) {
          ad.classList.add('show');
       }
    }, [favoriteAds]);

   useEffect(() => {
      if (data.activities && data.activities.data) {
         for (const ad of data.activities.data) {
            ad["excl"] = ad.excl_price > 0 ? 1 : 0;
         }
         new Promise((resolve, reject) => {
            setAds(data.activities.data);
            resolve();
         }).then(() => {
            updateShownAds(ads);
         })
      }

      if (data.bookings && data.bookings.data) {
         setBookings(data.bookings.data);
      }

   }, [loading]);

   const updateShownAds = (ads) => {

      const adElements = document.getElementsByClassName('ad');
      if (adElements.length == 0) return;
      let i = 0;
      const showAdInterval = setInterval(() => {

         if (adElements[i] == undefined) {
            clearInterval(showAdInterval);
            return;
         }

         adElements[i].classList.add('show');
         i++;
         if (i == adElements.length) {
            clearInterval(showAdInterval);
         }

      }, 35);

   }

   useEffect(() => {
      if (/\/hub\/?$/.test(location.pathname)) {
         navigate('/hub/profile', { replace: true });
      }

      document.title = "Hub - You Be Well";
   }, [location.pathname]);  

   useEffect(() => {
      updateShownAds();
   }, [bookings]);

   // prevent admin frontend access
   if (location.pathname.startsWith('/hub/admin') && !(["admin", "employee"].includes(user.type)) ) {
      navigate('/hub/profile');
   }

   return (
      <main id="hub">
         
         <Profile />

      </main>
   );

}